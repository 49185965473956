
















































import {Component, Vue} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import GoodsCodeAPIClass from "@/common/api/goodsCode";
export const pageApiList = getPageAPIs(GoodsCodeAPIClass);
const defaultForms = {
  name: '',
  price: '',
  category_id: '',
  bar_code: '',
  inventory: 1,
  inventory_warn_num: 1,
  wholesale_price: 0
}

@Component({})
export default class UserList extends Vue {
  private apiClass: any = new GoodsCodeAPIClass();
  private sellShow: boolean = false;
  private forms: any = {
    ...defaultForms
  };

  private form: any = {
    pool_name: "",
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.apiClass.getList(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.prepage = res.data.prepage;
        }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.getTable();
  }

  private restForms(): void {
    this.forms = new Util().clearObject(this.forms);
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }

  private category: any = [];

  private downCode(row:any): void {
    this.apiClass.getCode({pool_id:row.pool_id}, (res: any) => {
      console.log(res)
      Util.blobFileSaveAs(res, `qrcode_${row.pool_id}_${row.pool_name}.png`);
    });
  }


  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };


  private handleInputPrice(e: any) {
    this.forms.price = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
  }

  private handleInputWholesalePrice(e: any) {
    this.forms.wholesale_price = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
    if (this.forms.wholesale_price > 99999) {
      this.forms.wholesale_price = 99999
    } else if (this.forms.wholesale_price < 0) {
      this.forms.wholesale_price = 0
    }
  }

  private onInputWholesalePrice(value: any) {
    this.forms.wholesale_price = value.replace(/[^0-9.]/g, '')
    if (this.forms.wholesale_price > 99999) {
      this.forms.wholesale_price = 99999
    } else if (this.forms.wholesale_price < 0) {
      this.forms.wholesale_price = 0
    }
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
