import {BasicsClass} from "@/common/BasicsClass";

export default class GoodsCodeAPIClass extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/goods/qrCodeList',
            name: 'list',
            label: '商品二维码列表'
        }],
        ['code', {
            url: '/goods/qrCode',
            name: 'code',
            label: '商品二维码'
        }],
    ])


    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public getCode(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('code').url

        this.BasicPost(url, {
            ...params
        }, false, false, {
            responseType: 'blob',
        }, successCb, failCb, showLoading)
    }

}
